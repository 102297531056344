import MainButton from "../MainButton";

function PricingBox({ img, price, type }) {
  return (
    <>
      <div className="flex flex-col w-1/3 md1000:w-[40rem] bg-white shadow-xl relative min540:w-[100%]">
        <div
          style={{ transition: "all 0.3s" }}
          className="relative grayscale hover:grayscale-0"
        >
          <img src={img} alt="pricing_img" className="w-full h-full" />
          <div className="absolute bg-white text-[20px] font-bold w-[25rem] text-center py-6 text-[#ff0336] -bottom-[18px] left-0 right-0 mx-auto">
            {type}
          </div>
        </div>
        <div className="flex flex-col items-center pt-[20px] pb-[50px]">
          {/* price */}
          <p className="text-center text-[55px] font-bold relative py-[10px] ">
            <span className="text-[30px] text-[#6d6d6d] absolute font-normal top-8 -left-[3rem]">
              ₹
            </span>
            {price}
            <span className="text-[22px] text-[#6d6d6d] absolute font-normal bottom-[25px] -right-[6rem]">
              p/m
            </span>
          </p>
          {/* text */}
          <div className="flex flex-col text-[16px] font-medium text-center gap-8 text-[#646464] ">
            {type === "Basic" && (
              <>
                <p>Access to basic gym facilities (cardio machines, basic weightlifting equipment) </p>
                <p>Standard equipment usage</p>
                <p>General fitness classes (if applicable)</p>
                <p> Limited access to group</p>
                <p>Standard operating hours access</p>
              </>
            )}
            {type === "Intermediate" && (
              <>
                <p>All features included in the basic membership</p>
                <p>
                  Extended access to gym facilities (e.g., extended hours,
                  weekend access)
                </p>
                <p>Access to a wider range of fitness classes</p>
                <p>More advanced equipment available</p>
                <p>Access to additional amenities (sauna, locker room, etc.)</p>
                <p>Option to upgrade or downgrade</p>
              </>
            )}
            {type === "Professional" && (
              <>
                <p>
                  All features included in Intermediate memberships
                </p>
                <p>Priority access to equipment and classes</p>
                <p>
                  Exclusive access to advanced training sessions or workshops
                </p>
                <p>Personalized trainer and workout plans</p>
                <p>
                  Access to premium amenities (e.g., spa, nutrition counseling)
                </p>
                <p>Discounts on merchandise or additional services</p>
              </>
            )}
          </div>

          <MainButton
            color={`!text-white`}
            bg={`bg-[#ff0336]`}
            text="purchase now"
            arrowColor={`!text-white`}
            cN="pricing-cta"
            goTo="/contact"
          />
        </div>
      </div>
    </>
  );
}

export default PricingBox;
